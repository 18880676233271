import * as React from 'react';
import { ReactElement } from 'react';
import { Col, Row, Spin } from 'antd';

export const PageSpin = (): ReactElement => {
  return (
    <Row justify={'center'} style={{marginTop: '20px'}}>
      <Col>
        <Spin size={'large'} />
      </Col>
    </Row>
  );
};
