import React, {useContext, useEffect} from 'react';
import './App.css';
import {MainLayout} from "./layout/MainLayout";
import {Route, Routes} from "react-router";
import {Listing} from "./pages/Recordings/Listing";
import {Preview} from "./pages/Preview";
import {Analytics} from "./pages/Analytics";
import {Portal} from "./pages/Portal";
import {Team} from "./pages/Teams/Team";
import {KnowledgeBase} from "./pages/KnowledgeBase";
import {Integrations} from "./pages/Integrations";
import {Articles} from "./pages/Articles/Articles";
import {Dashboard} from "./pages/Dashboard";
import {AuthContext, AuthContextState, isSuperAdmin} from "./auth/AuthContext";
import {Login} from "./pages/login/Login";
import {SignOut} from "./pages/SignOut/SignOut";
import {Player} from "./pages/Player/Player";
import {FullPageSpin} from "./components/FullPageSpin";
import {Edit} from "./pages/Articles/Edit";
import {Amplitude} from './analytics/Amplitude';
import {useLocation} from "react-router-dom";
import {View} from './pages/Recordings/View';
import {clarity} from 'react-microsoft-clarity';
import {Admin} from "./pages/Admin";
import {LoginErrorOauth} from "./pages/login/LoginErrorOauth";
import {LoginSuccessOauth} from "./pages/login/LoginSuccessOauth";

function App() {
    const authContext = useContext<AuthContextState>(AuthContext);

    let location = useLocation();

    useEffect(() => {
        Amplitude.getInstance().then(async (a) => {
            await a.track('page-load');
        })
    }, [location]);

    useEffect(() => {
        if (authContext.isAuthorized) {
            Amplitude.getInstance().then(async (a) => {
                a.setUser(authContext.user);
            })
        }
    }, [authContext.isAuthorized, authContext.user]);

    useEffect(() => {
        const key = process.env.REACT_APP_CLARITY_KEY;
        if (key) {
            clarity.init(key);
        }
    }, [])

    if (authContext.isLoadingAuthorization) {
        return (
            <FullPageSpin visible={authContext.isLoadingAuthorization}/>
        );
    }

    if (!authContext.isAuthorized) {
        return (
            <Login/>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<MainLayout><Dashboard/></MainLayout>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/success-oauth-login" element={<LoginSuccessOauth/>}/>
            <Route path="/success-oauth-signup" element={<LoginSuccessOauth/>}/>
            <Route path="/error-oauth-login" element={<LoginErrorOauth/>}/>
            <Route path="/error-oauth-signup" element={<LoginErrorOauth/>}/>
            <Route path="/recordings" element={<MainLayout><Listing/></MainLayout>}/>
            <Route path="/recordings/:id" element={<MainLayout><View/></MainLayout>}/>
            <Route path="/preview" element={<MainLayout><Preview/></MainLayout>}/>
            <Route path="/portal" element={<MainLayout><Portal/></MainLayout>}/>
            <Route path="/team" element={<MainLayout><Team/></MainLayout>}/>
            <Route path="/knowledge-base" element={<MainLayout><KnowledgeBase/></MainLayout>}/>
            <Route path="/analytics" element={<MainLayout><Analytics/></MainLayout>}/>
            <Route path="/sign-out" element={<SignOut/>}/>
            <Route path="/integrations" element={<MainLayout><Integrations/></MainLayout>}/>
            <Route path="/articles" element={<MainLayout><Articles/></MainLayout>}/>
            <Route path="/articles/:id" element={<MainLayout><Edit/></MainLayout>}/>
            <Route path="/player/:id" element={<MainLayout><Player/></MainLayout>}/>
            <Route path="/admin" element={<MainLayout>{isSuperAdmin(authContext.user) ? (<Admin/>) : (<>Not
                found</>)}</MainLayout>}/>
        </Routes>
    );
}

export default App;
