import * as React from 'react';
import { ReactElement } from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import { DateTime } from 'luxon';

type FullPageSpinProps = {
  visible: boolean;
};

export const toggleVisibility = (
  setLoading: (v: boolean) => void,
  func: (...args: any[]) => Promise<void>,
  ...args: any[]
): (() => Promise<void>) => {
  return async (...inner: any[]) => {
    const start = DateTime.now();
    setLoading(true);
    await func(...args, ...inner);
    const end = DateTime.now();
    const duration = end.diff(start);
    if (duration.seconds >= 1) {
      setLoading(false);
    } else {
      setTimeout(function () {
        setLoading(false);
      }, 600);
    }
  };
};

export const FullPageSpin = ({ visible }: FullPageSpinProps): ReactElement => {
  return (
    <Modal
      open={visible}
      closable={false}
      centered={true}
      maskClosable={false}
      footer={null}
      width={100}
    >
      <Row justify={'center'}>
        <Col>
          <Spin size={'large'} />
        </Col>
      </Row>
    </Modal>
  );
};
