import React, {useState} from "react";
import {Space, Table} from 'antd';
import {ColumnsType} from "antd/es/table";
import {Button} from 'antd';
import {Link} from "react-router-dom";
import {Article} from "../../Domain/Article";
import { DeleteOutlined } from "@ant-design/icons";

export type ArticleTableProps = {
    onDelete: (id: string) => Promise<void>
    articles: Article[]
}

interface DataType {
    key: string;
    name: string;
    thumbnail: string;
    created: string;
    status: string;
}

export const ArticleTable = ({onDelete, articles}: ArticleTableProps) => {
    const [pageSize, setPageSize] = useState(10);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, i) => <Link to={`/articles/${i.key}`}>{text}</Link>,
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            render: (a, record) => (
                <Space size="middle">
                    <Button type={"link"} onClick={async () => await onDelete(record.key)} title={"Delete"}><DeleteOutlined /></Button>
                </Space>
            ),
            key: 'actions',
        }
    ];

    const data: DataType[] = articles.map((a: any) => {
        return {
            key: a.id,
            name: a.name,
            thumbnail: '',
            created: new Date(a.created).toLocaleString(),
            status: a.status,
        }
    })

    return (<Table columns={columns}
                   pagination={{
                       pageSize: pageSize,
                       onShowSizeChange: (c, s) => setPageSize(s)
                   }}
                   dataSource={data}/>);
}
