import {Button, Col, Row, Table, Typography} from "antd";
import {Fragment, useContext, useEffect, useState} from "react";
import * as React from "react";
import {AuthContext, AuthContextState} from "../auth/AuthContext";

export const Portal = () => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [url, setUrl] = useState('');

    useEffect(() => {
        if (authContext.isAuthorized) {
            setUrl((process.env.REACT_APP_PORTAL_URL ?? "") + "/portal/" + authContext.user.accountId + "/v1")
        }
    }, [authContext.isLoadingAuthorization])

    return (<Fragment>
        <Row>
            <Col>
                <Typography.Title level={1} style={{margin: 0}}>
                    Portal
                </Typography.Title>
            </Col>
        </Row>
        <Row>
            <Col>
                <Typography.Paragraph>
                    <blockquote>
                        Portal allows to browse published pages.
                    </blockquote>
                </Typography.Paragraph>
            </Col>
        </Row>
        <Row>
            <Col>
                <Typography.Title level={4}>HTML Code</Typography.Title>
            </Col>
        </Row>
        <Row>
            <Col>
                <Typography.Text copyable code>{`<iframe frameBorder="0" width="100%" height="100%" src="${url}" />`}</Typography.Text>
            </Col>
        </Row>
        <Row>
            <Col>
                <br />
            </Col>
        </Row>
        <Row>
            <Col>
                <Typography.Title level={4}>Preview</Typography.Title>
            </Col>
        </Row>
        <Row>
            <Col span={22} style={{minHeight: '60vh', border: '1px #ccc solid'}}>
                <iframe frameBorder={0} width={'100%'} height={'100%'} src={url} />
            </Col>
        </Row>
    </Fragment>);
}
