import React, {useRef, useState} from 'react';
import {Button, Col, Row, Select, Tooltip, Typography} from 'antd';
import {VideoRecording} from "../VideoRecording";
import {MinusOutlined, PlusOutlined, ScissorOutlined} from "@ant-design/icons";
import {PlayerAction} from "../PlayerAction";
import {PlayerState, Suggestion} from "../PlayerState";

const {Title, Paragraph, Text, Link} = Typography;

type TextSuggestionProps = {
    suggestion: Suggestion;
    dispatch: React.Dispatch<PlayerAction>;
};

export const TextSuggestion = ({
                                   suggestion,
                                   dispatch
                               }: TextSuggestionProps) => {


    return (
        <Row>
            <Col span={19}>
                {suggestion.text}
            </Col>
        </Row>
    );
}
