import React, {useEffect, useState} from "react";
import {Spin, Typography} from 'antd';
import {Card, Col, Row, Statistic} from 'antd';
import {Segmented} from 'antd';
import {Timeline} from 'antd';
import {sendGet} from "../http/requester";
import {FullPageSpin} from "../components/FullPageSpin";
import {PageSpin} from "../components/PageSpin";
import {Link} from "react-router-dom";

const {Title, Paragraph, Text,} = Typography;


export type Timeline = {
    position: string,
    label: string,
    color: string,
    crated: Date,
    text: string,
    ids: string[],
    type: string,
}

export type Data = {
    recordings: number,
    articles: number,
    screens: number,
    timeline: Timeline[]
}

export const Dashboard = () => {
    const data = [
        (<p>Process 1 new <a href={"/recordings"}>recording</a></p>),
        (<p>Review 3 <a href={"/recordings"}>articles</a></p>),

    ];

    const [stat, setStat] = useState<Data | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sendGet('stats').then((res) => res.json()).then((r) => {
            setStat(r);
            setLoading(false);
        })
    }, []);

    if (loading) {
        return (<><Title>Dashboard</Title>
            <PageSpin/>
        </>);
    }

    if (stat) {
        return (<><Title>Dashboard</Title>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Segmented disabled={true} options={['Last 7 days', 'Last 14 days', 'Last 30 days', 'All']}
                               defaultValue={'All'}/>
                </Col>
            </Row>
            <Row>
                <Col><br/></Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Recordings uploaded by users"
                            value={stat?.recordings}
                            valueStyle={{color: '#3f8600'}}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Articles generated by AI"
                            value={stat?.articles}
                            valueStyle={{color: '#cf1322'}}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Screencasts generated by AI"
                            value={stat?.screens}
                            valueStyle={{color: '#1677ff'}}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Saved hours for your team"
                            value={(stat.recordings * 0.3 + stat.articles * 1.2).toFixed(2)}
                            valueStyle={{color: '#3f8600'}}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col><br/></Col>
            </Row>
            <Row>
                <Col><br/></Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Timeline
                        mode={'alternate'}
                        items={stat.timeline.map(t => {
                            if (t.type === 'NewArticle') {
                                let text = <p>A new article was generated - <Link to={`/articles/${t.ids[0]}`}>see</Link></p>;
                                if(1 < t.ids.length) {
                                    text = <p>A new articles were generated - <Link to={`/articles`}>see</Link></p>;
                                }
                                return {
                                    position: t.position,
                                    label: '',
                                    children: (<p>{text}</p>),
                                    color: t.color,
                                }
                            } else if (t.type === 'NewRecording') {
                                let text = <p>A new recording was generated - <Link to={`/recordings/${t.ids[0]}`}>see</Link></p>;
                                if(1 < t.ids.length) {
                                    text = <p>A new recordings were generated - <Link to={`/recordings`}>see</Link></p>;
                                }
                                return {
                                    position: t.position,
                                    label: '',
                                    children: (<p>{text}</p>),
                                    color: t.color,
                                }
                            } else {
                                return {
                                    position: t.position,
                                    label: '',
                                    children: (<p>{t.text}</p>),
                                    color: t.color,
                                }
                            }
                        })}
                    />
                </Col>
                {/*<Col span={12}>*/}
                {/*    <List*/}
                {/*        header={<div>Things to do next</div>}*/}
                {/*        bordered*/}
                {/*        dataSource={data}*/}
                {/*        renderItem={(item) => (*/}
                {/*            <List.Item>*/}
                {/*                {item}*/}
                {/*            </List.Item>*/}
                {/*        )}*/}
                {/*    />*/}
                {/*</Col>*/}
            </Row>
        </>);
    }

    return (<><Title>Dashboard</Title></>);
}
