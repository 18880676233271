// @ts-nocheck

import React, {Reducer, useEffect, useReducer, useRef, useState} from 'react';
import {Button, Col, Input, InputNumber, Modal, Row, Select, Tabs, Typography} from 'antd';
import {useParams} from "react-router-dom";
import {sendGet, sendPost} from "../../http/requester";
import {PageSpin} from "../../components/PageSpin";

import FilerobotImageEditor, {TABS, TOOLS,} from 'react-filerobot-image-editor';
import {CopyOutlined, DeleteOutlined, EditOutlined, ScissorOutlined} from "@ant-design/icons";
import {EventViewer} from "./EventViewer";
import {PromptBuilder} from "./PromptBuilder";
import {PlayerState} from "./PlayerState";
import {PlayerAction} from "./PlayerAction";

import {ArticleEditor} from "../../Lexical/ArticleEditor";
import {Suggestions} from "./Suggestions/Suggestions";
import {Confirm, showConfirm} from "../../components/Confirm";
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {FromJson} from "./FromJson";

const {Title, Paragraph, Text, Link} = Typography;

const initialState: PlayerState = {
    suggestions: [],
    data: null,
    loading: true,
    video: {
        scroll: 0,
        x: 0,
        y: 0,
        capture_type: "clean",
        text: '',
        lupe_type: 'circleAtLeast300px'
    }
};

const getState = (
    previous: PlayerState,
    overwrite: any = {},
): PlayerState => {
    const next = {
        ...previous,
        ...overwrite,
    };

    return next;
};

const reducer: Reducer<PlayerState, PlayerAction> = (
    state: PlayerState,
    action: PlayerAction,
): PlayerState => {
    switch (action.type) {
        case "set-data":
            return getState(state, {data: action.data, name: action.data.name, loading: false})
        case "set-html":
            return getState(state, {html: action.html})
        case "set-name":
            return getState(state, {name: action.name})
        case "set-progress-step":
            return getState(state, {data: {
                    ...state.data,
                    progressStep: action.step
                }})
        case "set-raw":
            return getState(state, {raw: action.raw})
        case "set-chat-response":
            return getState(state, {chatResponse: action.chatResponse})
        case "add-empty-suggestion":
            const newSuggestions = [...state.suggestions];
            newSuggestions.push({
                name: "",
                id: newSuggestions.length + 1,
                suggestionType: action.suggestionType,
                text: "",
                carousel: ''
            });
            return getState(state, {suggestions: newSuggestions});
        case "remove-suggestion":
            return getState(state, {suggestions: state.suggestions.filter((s) => s.id !== action.idToDelete)});
        case "clear-suggestions":
            return getState(state, {suggestions: []});
        case "add-carousel-suggestion":
            let ns = [...state.suggestions];
            ns.push({suggestionType: "carousel", carousel: action.content, name: '', id: ns.length + 1, text: ''})
            return getState(state, {suggestions: ns});
        case "update-suggestion":
            const index = state.suggestions.findIndex((s) => s.id === action.idToUpdate);
            if (-1 < index) {
                state.suggestions[index] = action.newSuggestion;
                return getState(state, {suggestions: state.suggestions});
            } else {
                return getState(state);
            }
        case "update-video-scroll":
            state.video.current.currentTime = action.scroll;
            return getState(state, {
                video: {
                    ...state.video,
                    scroll: action.scroll
                }
            });
        case "set-prompt":
            return getState(state, {
                data: {
                    ...state.data,
                    prompt: action.prompt
                }
            });
        case "update-video-state":
            state.video.current.currentTime = action.scroll;
            return getState(state, {
                video: {
                    ...state.video,
                    scroll: action.scroll,
                    x: action.x,
                    y: action.y,
                }
            });
        case "set-video-ref":
            return getState(state, {
                video: {
                    ...state.video,
                    current: action.video
                }
            });
        case "update-video-state-x":
            return getState(state, {
                video: {
                    ...state.video,
                    x: action.x
                }
            });
        case "update-video-state-y":
            return getState(state, {
                video: {
                    ...state.video,
                    y: action.y
                }
            });
        case "set-capture-type":
            return getState(state, {
                video: {
                    ...state.video,
                    capture_type: action.capture
                }
            });
        case "set-lupe-type":
            return getState(state, {
                video: {
                    ...state.video,
                    lupe_type: action.lupeType
                }
            });
        case "update-video-state-text":
            return getState(state, {
                video: {
                    ...state.video,
                    text: action.text
                }
            });
        case "update-mouse-over-video-coordinates":
            return getState(state, {
                video: {
                    ...state.video,
                    mouseX: action.x,
                    mouseY: action.y,
                }
            });
        default:
            throw new Error();
    }
};

export const Player = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    let {id} = useParams();
    const video = useRef<HTMLVideoElement>(null);
    const [loading, setLoading] = useState(true);
    const [capture, setCapture] = useState<string[]>([]);
    const [imageEditorOpen, setImageEditorOpen] = useState<string | null>(null);
    const [imageViewerOpen, setImageViewerOpen] = useState<string | null>(null);
    const [spin, setSpin] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const isMouseEvent = (type: string) => {
        return type === 'mousemove' || type === 'mouseover' || type === 'mouseout' || type === 'mouseenter';
    };
    const isEqualHtmlEvents = (previous: any, current: any) => {
        let result = previous.event.type === current.event.type;
        if (!result) {
            result = isMouseEvent(previous.event.type) && isMouseEvent(current.event.type);
        }
        return result;
    }

    useEffect(() => {
        if (video.current) {
            dispatch({type: 'set-video-ref', video: video.current})
        }
    }, [video, video.current])

    useEffect(() => {
        sendGet('player/' + id).then((res) => res.json()).then((r) => {
            let events = [];
            if (r.events && 0 < r.events.length) {
                events = JSON.parse(r.events);
            }
            let newEvents: any[] = [];
            let currentGroup = [];
            if (0 < events.length) {
                const first = events[0];
                first.subEvents = [];
                newEvents.push(first);
                for (let i = 1; i < events.length; i++) {
                    const currentEvent = events[i];
                    currentEvent.subEvents = [];
                    const previousEvent = newEvents[newEvents.length - 1];
                    if (previousEvent.type === 'HtmlEvent' && currentEvent.type === 'HtmlEvent') {
                        if (isEqualHtmlEvents(previousEvent, currentEvent)) {
                            currentGroup.push(currentEvent);
                        } else {
                            previousEvent.subEvents = currentGroup;
                            currentGroup = [];
                            newEvents.push(currentEvent);
                        }
                    } else {
                        previousEvent.subEvents = currentGroup;
                        currentGroup = [];
                        newEvents.push(currentEvent);
                    }
                }
            }
            r.events = newEvents;
            setLoading(false);

            dispatch({type: "set-data", data: r});
            if (r.chatGtpAnswer) {
                const choices = JSON.parse(r.chatGtpAnswer).choices;
                let chatResponse = choices[0].message.content;
                if (1 < choices.length) {
                    chatResponse = 'WARNING several choices\r\n' + chatResponse;
                }
                dispatch({type: "set-chat-response", chatResponse: chatResponse});
            }
        })
    }, [id]);

    const onCapture = async () => {
        if (video.current) {
            const captureResponse = await sendPost('player/' + id + '/capture', {
                timeStamp: state.video.current.currentTime,
                x: state.video.x,
                y: state.video.y,
                text: state.video.text,
                captureType: state.video.capture_type,
                lupeType: state.video.lupe_type,
            }).then((res) => res.json());

            const captures = [...capture];
            // @ts-ignore
            captures.push(captureResponse.image);
            setCapture(captures);

            // @ts-ignore
            await fetch(captureResponse.image)
                .then(async (res) => {
                    const items = [new ClipboardItem({'image/png': res.blob()})]
                    await navigator.clipboard.write(items);
                })
        }
    }

    const onSaveArticle = async () => {
        await sendPost('player', {
            suggestions: JSON.stringify(state.suggestions),
            name: state.name,
            html: state.html,
            raw: state.raw,
            recordingId: id
        })
    };

    if (state.loading) {
        return (<>
            <Title>Player</Title>
            <PageSpin/>
        </>);
    }

    return (<>
        <Confirm visible={confirm}/>
        <FullPageSpin visible={spin}/>
        <Modal title=""
               width={1200}
               open={imageEditorOpen !== null}
               bodyStyle={{height: '600px'}}
               closeIcon={null}
               footer={null}
               closable={false}
               onOk={() => setImageEditorOpen(null)}
               onCancel={() => setImageEditorOpen(null)}>
            {imageEditorOpen !== null && <FilerobotImageEditor
                source={imageEditorOpen}
                onSave={async (editedImageObject, designState) => {
                    if (editedImageObject.imageBase64) {
                        await fetch(editedImageObject.imageBase64)
                            .then(async (res) => {
                                const items = [new ClipboardItem({'image/png': res.blob()})]
                                await navigator.clipboard.write(items);
                            })
                    }
                }}
                onClose={() => setImageEditorOpen(null)}
                Crop={{
                    presetsItems: [
                        {
                            titleKey: 'classicTv',
                            descriptionKey: '4:3',
                            ratio: 4 / 3,
                            // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                        },
                        {
                            titleKey: 'cinemascope',
                            descriptionKey: '21:9',
                            ratio: 21 / 9,
                            // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                        },
                    ],
                    presetsFolders: [
                        {
                            titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
                            // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                            groups: [
                                {
                                    titleKey: 'facebook',
                                    items: [
                                        {
                                            titleKey: 'profile',
                                            width: 180,
                                            height: 180,
                                            descriptionKey: 'fbProfileSize',
                                        },
                                        {
                                            titleKey: 'coverPhoto',
                                            width: 820,
                                            height: 312,
                                            descriptionKey: 'fbCoverPhotoSize',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                }}
                tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]}
                defaultTabId={TABS.ANNOTATE}
                defaultToolId={TOOLS.TEXT}
                previewPixelRatio={1}
                savingPixelRatio={1}/>}
        </Modal>
        <Modal title=""
               width={1200}
               open={imageViewerOpen !== null}
               bodyStyle={{width: '90vw'}}
               closeIcon={null}
               footer={null}
               closable={true}
               onOk={() => setImageViewerOpen(null)}
               onCancel={() => setImageViewerOpen(null)}>
            {imageViewerOpen !== null && <img src={imageViewerOpen} style={{width: '77vw'}} alt={'Image'}/>}
        </Modal>
        <Row align={"middle"} style={{borderBottom: '1px #ccc solid'}}>
            <Col span={16}>
                <Title level={3}>Player - {state.data?.name}</Title>
            </Col>
            <Col span={1}>
                Step:
            </Col>
            <Col span={7}>
                <Select
                    dropdownMatchSelectWidth={true}
                    style={{width: '100%'}}
                    placeholder="Select a state"
                    value={state.data.progressStep}
                    onChange={toggleVisibility(setSpin, async (c) => {
                        const response = await sendPost('player/' + state.data.id + '/step', {
                            newStep: c
                        });
                        if (response.ok) {
                            dispatch({type: 'set-progress-step', step: c})
                        }
                    })}
                    options={['Uploaded', 'Processing', 'Processed', 'Failed']
                        .map(s => {
                            return {value: s, label: s}
                        })}
                />
            </Col>
        </Row>
        <Row justify="space-between" style={{marginTop: '7px'}}>
            <Col span={15}>
                <Row>
                    <Col>
                        <video ref={video}
                               style={{width: '100%', height: '50vh'}}
                               onMouseMove={(e) => {
                                   const target = e.target.getBoundingClientRect();
                                   dispatch({
                                       type: 'update-mouse-over-video-coordinates',
                                       x: ((video.current.videoWidth / target.width) * (e.clientX - target.x)).toFixed(0),
                                       y: ((video.current.videoHeight / target.height) * (e.clientY - target.y)).toFixed(0)
                                   })
                               }}
                               controls={true}>
                            <source type="video/webm" src={state.data?.video}/>
                        </video>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {state.video.mouseX}; {state.video.mouseY}
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <EventViewer dispatch={dispatch} state={state}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <br/>
            </Col>
        </Row>
        <Row>
            <Col span={16}>
                <Row align={"middle"}>
                    <Col span={2}>
                        Time:&nbsp;
                    </Col>
                    <Col>
                        <InputNumber
                            min={0}
                            max={state.data?.duration}
                            step={0.05}
                            style={{marginRight: '16px'}}
                            value={state.video.scroll}
                            onChange={(v) => {
                                const time = v ?? 0;
                                dispatch({type: 'update-video-scroll', scroll: time});
                            }
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                </Row>
                <Row align={"middle"}>
                    <Col span={2}>
                        Type:&nbsp;
                    </Col>
                    <Col span={5}>
                        <Select
                            dropdownMatchSelectWidth={true}
                            placeholder="Select a capture type"
                            optionFilterProp="children"
                            style={{marginRight: '16px', width: '100%'}}
                            onChange={(e) => dispatch({type: 'set-capture-type', capture: e})}
                            value={state.video.capture_type}
                            options={[{value: 'numberInCircle', label: 'Number in circle'},
                                {value: 'clean', label: 'Clean'},
                                {value: 'lupeAndNumberInCircle', label: 'Lupe and Number in circle'},
                                {value: 'lupe', label: 'Lupe'}]}
                        />
                    </Col>
                    <Col span={4}>
                        &nbsp;&nbsp;&nbsp;Lupe Type:
                    </Col>
                    <Col span={7}>
                        <Select
                            dropdownMatchSelectWidth={true}
                            placeholder="Select a lupe type"
                            optionFilterProp="children"
                            style={{marginRight: '16px', width: '100%'}}
                            onChange={(e) => dispatch({type: 'set-lupe-type', lupeType: e})}
                            value={state.video.lupe_type}
                            options={[{value: 'circleAtLeast300px', label: 'Circle at least 300 px'},
                                {value: 'circle300px', label: 'Circle 300 px'},
                                {value: 'circle150px', label: 'Circle 150 px'}]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                </Row>
                <Row align={"middle"}>
                    <Col span={2}>
                        Text:&nbsp;
                    </Col>
                    <Col span={20}>
                        <Input value={state.video.text}
                               onChange={(v) => {
                                   const y = v ?? 0;
                                   dispatch({type: 'update-video-state-text', text: v.target.value});
                               }
                               }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                </Row>
                <Row align={"middle"}>
                    <Col span={2}>
                        X:&nbsp;
                    </Col>
                    <Col>
                        <InputNumber
                            min={0}
                            step={10}
                            style={{marginRight: '16px'}}
                            value={state.video.x}
                            onChange={(v) => {
                                const x = v ?? 0;
                                dispatch({type: 'update-video-state-x', x: x});
                            }
                            }
                        />
                    </Col>
                    <Col span={2}>
                        Y:&nbsp;
                    </Col>
                    <Col>
                        <InputNumber
                            min={0}
                            step={10}
                            value={state.video.y}
                            onChange={(v) => {
                                const y = v ?? 0;
                                dispatch({type: 'update-video-state-y', y: y});
                            }
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row justify={"start"}>
                    <Col>
                        <Button type={"primary"} onClick={onCapture} icon={<ScissorOutlined/>}>Capture</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>
                &nbsp;
            </Col>
        </Row>
        <Row>
            {capture.map((c, i) => {
                return (
                    <Col span={2} key={`image-${i}`}>
                        <Row>
                            <Col>
                                <img src={c}
                                     onClick={() => setImageViewerOpen(c)}
                                     style={{width: '100px', border: '1px #ccc solid'}} alt={"1"}/>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col>
                                <Button type="primary" shape="circle" onClick={() => setImageEditorOpen(c)}
                                        icon={<EditOutlined/>}/>
                            </Col>
                            <Col>
                                &nbsp;
                            </Col>
                            <Col>
                                <Button type="primary" shape="circle" onClick={() => {
                                    const captures = [...capture];
                                    const index = captures.indexOf(c);
                                    captures.splice(index, 1)
                                    setCapture(captures);
                                }} icon={<DeleteOutlined/>}/>
                            </Col>
                            <Col>
                                &nbsp;
                            </Col>
                            <Col>
                                <Button type="primary" shape="circle" onClick={async () => {
                                    await navigator.clipboard.writeText(c);
                                }} icon={<CopyOutlined/>}/>
                            </Col>
                        </Row>
                    </Col>)
            })}
        </Row>
        <Row>
            <Col span={24} style={{borderBottom: '1px #ccc solid'}}>
                &nbsp;
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'Editor',
                            key: '1',
                            children: (<>
                                <Row>
                                    <Col span={24} style={{margin: '20px auto 0 auto', maxWidth: '1100px'}}>
                                        <Input value={state.name} placeholder={'Name'} onChange={(e) => {
                                            dispatch({type: 'set-name', name: e.target.value})
                                        }}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <ArticleEditor setHtml={(h: string) => {
                                            dispatch({type: 'set-html', html: h})
                                        }} setRaw={(r: string) => {
                                            dispatch({type: 'set-raw', raw: r})
                                        }} raw={state.raw}/>
                                    </Col>
                                </Row>
                            </>),
                        },
                        {
                            label: 'From JSON',
                            key: '2',
                            children: (<Row>
                                <Col span={12}>
                                    <FromJson dispatch={dispatch} state={state}/>
                                </Col>
                                <Col span={12}>
                                    <PromptBuilder dispatch={dispatch} state={state}/>
                                </Col>
                            </Row>),
                        }
                    ]}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <br/>
                <br/>
                <br/>
            </Col>
        </Row>
        <Row style={{clear: 'both'}}>
            <Col span={24}>
                <Suggestions dispatch={dispatch} state={state}/>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{borderBottom: '1px #ccc solid'}}>
                &nbsp;
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <br/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Button onClick={showConfirm(setConfirm, toggleVisibility(setSpin, onSaveArticle))}
                        type={"primary"}>Save</Button>
            </Col>
        </Row>
    </>);
}
