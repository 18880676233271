import React from "react";
import { Typography } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;

export const KnowledgeBase = () => {
    return (<><Title>Knowledge Base</Title>
        <Paragraph>
            <blockquote>New recordings help maintain and improve <a href={"/knowledge-base"}>knowledge base</a>.</blockquote>
        </Paragraph></>);
}