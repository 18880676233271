import React, {useEffect, useState} from "react";
import {Typography} from 'antd';
import {Col, Row} from 'antd';
import {Button} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {sendGet, sendPost} from "../../http/requester";
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {Confirm, showConfirm} from "../../components/Confirm";
import {PageSpin} from "../../components/PageSpin";
import {ArticleTable} from "./ArticleTable";


const {Title, Paragraph, Text} = Typography;

export const Articles = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [spin, setSpin] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const navigator = useNavigate();

    const readArticles = () => {
        sendGet('articles').then((res) => res.json()).then((r) => {
            setData(r);
            setLoading(false);
        })
    }

    const create = async () => {
        const r = await sendPost('articles', {
            name: 'New'
        });
        const d = await r.json();
        navigator(`/articles/${d.id}`);
    }

    useEffect(() => {
        readArticles();
    }, []);

    let body = (<PageSpin/>);
    if (!loading) {
        body = (<ArticleTable onDelete={showConfirm(setConfirm, toggleVisibility(setSpin, async (id) => {
            await sendPost('articles/' + id + '/delete').then((r) => {
                readArticles();
            })
        }))} articles={data} />);
    }

    return (<>
            <Confirm visible={confirm}/>
            <FullPageSpin visible={spin}/>
            <Title>Articles</Title>
            <Row>
                <Col span={20}>
                    <Paragraph>
                        <blockquote>Generative AI writes content for a new articles or suggests edits based on <Link
                            to={'/recordings'}>recordings</Link>.
                        </blockquote>
                    </Paragraph>
                </Col>
                <Col span={4} style={{textAlign: 'right'}}>
                    <Button onClick={toggleVisibility(setSpin, create)} type="primary">+ Add new article</Button>
                </Col>
            </Row>
            {body}
        </>
    );
}
