import { ReactElement, ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import { me } from '../http/requester';

export type ApplicationUser = {
  email: string;
  id: string;
  accountId: number;
  created: string;
  role: string;
};

const defaultApplicationUser = {
  email: '',
  id: '',
  accountId: 0,
  created: '',
  role: '',
};

export type AuthContextState = {
  isAuthorized: boolean;
  isLoadingAuthorization: boolean;
  user: ApplicationUser;
};

const defaultAuthContextState = {
  isAuthorized: false,
  isLoadingAuthorization: true,
  user: defaultApplicationUser,
};

export const AuthContext = React.createContext<AuthContextState>(
  defaultAuthContextState,
);

type AuthContextProps = {
  children?: ReactNode | undefined;
};

export const isSuperAdmin = (user: ApplicationUser) => {
  return process.env.NODE_ENV === 'development' || user.role === 'SuperAdmin'
}

export const Auth = ({ children }: AuthContextProps): ReactElement => {
  const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
  const [user, setUser] = useState<ApplicationUser>(defaultApplicationUser);
  const [isLoadingAuthorization, setIsLoadingAuthorization] = useState(true);

  useEffect(() => {
    me().then((user) => {
      if (user) {
        setIsAuthorizedUser(true);
        setUser(user);
      }
      setIsLoadingAuthorization(false);
    }).catch((e) => {
      setIsAuthorizedUser(false);
      setIsLoadingAuthorization(false);
    })
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthorized: isAuthorizedUser, isLoadingAuthorization, user }}
    >
      {children}
    </AuthContext.Provider>
  );
};
