import React, {useRef, useState} from 'react';
import {Button, Col, Input, Row, Select, Tooltip, Typography} from 'antd';
import {VideoRecording} from "../VideoRecording";
import {MinusOutlined, PlusOutlined, ScissorOutlined} from "@ant-design/icons";
import {PlayerAction} from "../PlayerAction";
import {PlayerState, Suggestion} from "../PlayerState";
const { Title, Paragraph, Text, Link } = Typography;

type TextSuggestionProps = {
    suggestion: Suggestion;
};

export const CarouselSuggestion = ({

                                   suggestion
                               }: TextSuggestionProps) => {


    return (
        <Row>
            <Col span={19} style={{height: '300px'}}>
                <iframe frameBorder={0}
                        style={{width: '100%',
                            border: '1px #ccc solid',
                            height: '100%'}}
                        srcDoc={"<!doctype html>\n" +
                            "<html lang=\"en\">" +
                            "  <head>" +
                            "    <meta charset=\"utf-8\">" +
                            "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">" +
                            "    <link href=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css\" rel=\"stylesheet\" integrity=\"sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ\" crossorigin=\"anonymous\">" +
                            "  </head>" +
                            "  <body contenteditable='true'>" +
                            suggestion.carousel +
                            "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js\" integrity=\"sha384-ENjdO4Dr2bkBIFxQpeoTz1HIcje39Wm4jDKdf19U8gI4ddQ3GYNS7NTKfAdVQSZe\" crossorigin=\"anonymous\"></script>" +
                            "  </body>" +
                            "</html>"}
                />
            </Col>
        </Row>);
}
