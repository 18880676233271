import * as React from 'react';
import {ReactElement, useContext} from 'react';
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import {AuthContext, AuthContextState} from "../auth/AuthContext";

type ChatProps = {
    propertyId: string;
    widgetId: string;
    enabled: boolean;
};

export const Chat = ({propertyId, widgetId, enabled = true}: ChatProps): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);

    const onLoad = () => {
        // @ts-ignore
        window.Tawk_API.setAttributes({
            'name'  : authContext.user.email,
            'email' : authContext.user.email,
            'user_id' : authContext.user.id,
            'account_id' : authContext.user.accountId
        }, function(){});
    };

    if (enabled) {
        return (<div className="App">
            <TawkMessengerReact
                propertyId={propertyId}
                onLoad={onLoad}
                widgetId={widgetId}/>
        </div>)
    }

    return (<></>)
};
