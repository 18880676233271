import { ApiRouter } from './apiRouteBuilder';

export class RouterBuilder {
  apiUrl: string;
  authUrl: string;
  api: ApiRouter;

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL ?? "";
    this.authUrl = process.env.REACT_APP_AUTH_URL ?? "";
    this.api = new ApiRouter(this.apiUrl, this.authUrl);
  }

  home(): string {
    return '/';
  }
  marketingHome(): string {
    return 'https://docudo.xyz';
  }

  createAccount(): string {
    return 'https://docudo.xyz/sign-up';
  }

  login(): string {
    return '/login';
  }

  signOut(): string {
    return '/sign-out';
  }
}

export const Routes = new RouterBuilder();
