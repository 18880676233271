import React, {useRef, useState} from 'react';
import {Button, Col, Input, Row, Select, Tooltip, Typography} from 'antd';
import {VideoRecording} from "../VideoRecording";
import {MinusOutlined, PlusOutlined, ScissorOutlined} from "@ant-design/icons";
import {PlayerAction} from "../PlayerAction";
import {PlayerState, Suggestion} from "../PlayerState";

type NameSuggestionProps = {
    suggestion: Suggestion;
    dispatch: React.Dispatch<PlayerAction>;
};

export const NameSuggestion = ({
    dispatch,
                                       suggestion
                                   }: NameSuggestionProps) => {


    const onChange = (e: any) => {
        const n = {
            ...suggestion,
            name: e.target.value
        }
        dispatch({type: "update-suggestion", idToUpdate: suggestion.id, newSuggestion: n})
    }

    return (
        <Row>
            <Col span={19}>
                <Input type={'text'} value={suggestion.name} onChange={onChange} ></Input>
            </Col>
        </Row>);
}
