import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'antd';
import {CopyOutlined, UndoOutlined} from "@ant-design/icons";
import { Input } from 'antd';
import {PlayerAction} from "./PlayerAction";
import {PlayerState} from "./PlayerState";
import {sendPost} from "../../http/requester";

const { TextArea } = Input;

type FromJsonProps = {
    dispatch: React.Dispatch<PlayerAction>;
    state: PlayerState;
};

export const FromJson = ({
                                  dispatch,
                                  state
                              }: FromJsonProps) => {
    const onConvert = async () => {
        if (state.chatResponse) {
            const response = await sendPost(`player/${state.data?.id}/create-article`, {
                chatGtpResponse: state.chatResponse,
            })
            const data = await response.json();
            dispatch({type: 'set-raw', raw: data.raw[0].raw})
            dispatch({type: 'set-name', name: data.raw[0].name})
        }
    };

    return (
        <Row justify={"center"}>
            <Col span={20}>
                <Row>
                    <Col span={24}>
                        <TextArea style={{width: '100%', height: '300px'}}
                                  value={state.chatResponse}
                                  onChange={(c) => dispatch({type: 'set-chat-response', chatResponse: c.target.value})}
                                  allowClear={true}
                                  autoComplete={'false'}
                                  autoCorrect={'false'}
                                  rows={8} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="primary" onClick={onConvert}>Copy to Editor</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
