import React, {useContext, useEffect, useState} from "react";
import {Button, Table, Typography} from 'antd';
import Auth, {sendGet, sendPost, sendTokenToChromeExtension} from "../http/requester";
import {PageSpin} from "../components/PageSpin";
import {AuthContext, AuthContextState} from "../auth/AuthContext";
import {ColumnsType} from "antd/es/table";

const {Title} = Typography;


export type Data = {
    accountId: number,
    articles: number,
    recordings: number,
    publishedArticles: number,
    accountName: string,
    userId: string,
    userCreated: string,
    userName: string,
}

export const Admin = () => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [pageSize, setPageSize] = useState(10);

    const [stat, setStat] = useState<Data[] | null>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sendGet('admin/accounts').then((res) => res.json()).then((r) => {
            setStat(r);
            setLoading(false);
        })
    }, []);

    const onLogin = async (accountId: number) => {
        sendPost('admin/impersonate', {
            accountId: accountId
        }).then().then((r) => r.json()).then(async (r) => {
            await Auth.setToken(r.token);
            await sendTokenToChromeExtension(r.token);
            window.location.href = '/';
        })
    };

    if (loading) {
        return (<><Title>Admin</Title>
            <PageSpin/>
        </>);
    }

    const columns: ColumnsType<Data> = [
        {
            title: 'Id',
            dataIndex: 'accountId',
            key: 'accountId',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.accountId - b.accountId,
        },
        {
            title: 'Name',
            dataIndex: 'accountName',
            key: 'accountName',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.accountName.localeCompare(b.accountName),
        },
        {
            title: 'UserId',
            dataIndex: 'userId',
            key: 'userId',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.userId.localeCompare(b.userId),
        },
        {
            title: 'UserName',
            dataIndex: 'userName',
            key: 'userName',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.userName.localeCompare(b.userName),
        },
        {
            title: 'UserCreated',
            dataIndex: 'userCreated',
            key: 'userCreated',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.accountId - b.accountId,
        },
        {
            title: 'Recordings',
            dataIndex: 'recordings',
            key: 'recordings',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.recordings - b.recordings,
        },
        {
            title: 'Articles',
            dataIndex: 'articles',
            key: 'articles',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.articles - b.articles,
        },
        {
            title: 'Published',
            dataIndex: 'publishedArticles',
            key: 'publishedArticles',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.publishedArticles - b.publishedArticles,
        },
        {
            title: 'Actions',
            render: (a, record) => {
                return (
                    <>{authContext.user.accountId !== a.accountId && (<Button onClick={async () => await onLogin(a.accountId)}>Login</Button>)}</>
                )
            },
            key: 'actions',
        }
    ];

    return (<><Title>Admin</Title>
        <Table columns={columns}
               pagination={{
                   pageSize: pageSize,
                   onShowSizeChange: (c, s) => setPageSize(s)
               }}
               dataSource={stat ?? []}/>
    </>);
}
