import {LexicalEditor} from "lexical";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {DialogButtonsList} from "../../ui/Dialog";
import Button from "../../ui/Button";
import {
    INSERT_CAROUSEL_COMMAND,
    InsertCarouselPayload
} from "./CarouselPlugin";
import TextInput from "../../ui/TextInput";

export function InsertCarouselDialog({
                                         activeEditor,
                                         onClose,
                                     }: {
    activeEditor: LexicalEditor;
    onClose: () => void;
}): JSX.Element {
    const [mode, setMode] = useState<null | 'url' | 'file'>(null);
    const hasModifier = useRef(false);
    const [images, setImages] = useState<string[]>(['']);

    useEffect(() => {
        hasModifier.current = false;
        const handler = (e: KeyboardEvent) => {
            hasModifier.current = e.altKey;
        };
        document.addEventListener('keydown', handler);
        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [activeEditor]);

    const onClick = (payload: InsertCarouselPayload) => {
        activeEditor.dispatchCommand(INSERT_CAROUSEL_COMMAND, payload);
        onClose();
    };

    const onImageChange = (j: number, value: string) => {
      const ims = [...images];
      ims[j] = value;
      setImages(ims)
    };

    return (
        <>
            {!mode && (
                <DialogButtonsList>
                    {images.map((i, j) => {
                        return (<TextInput
                            key={`input-${j}`}
                            label="Image Url"
                            placeholder="https://example.com"
                            onChange={(newValue) => onImageChange(j, newValue)}
                            value={i}
                            data-test-id="image-modal-alt-text-input"
                        />);
                    })}

                    <Button
                        data-test-id="image-modal-option-sample"
                        onClick={() => {
                            setImages([...images, ''])
                        }}>
                        Add image
                    </Button>

                    <Button
                        data-test-id="image-modal-option-sample"
                        onClick={() =>
                            onClick({
                                    images,
                                    carouselId: 'carousel-' + Date.now().toString().substring(6),
                                    showCaption: true
                                }
                            )
                        }>
                        Save
                    </Button>
                </DialogButtonsList>
            )}
        </>
    );
}
