import React, {ReactNode, useRef, useState} from 'react';
import {Button, Col, Row, Select, Tabs, Typography} from 'antd';
import {PlayerAction} from "../PlayerAction";
import {PlayerState, SuggestionType} from "../PlayerState";
import {TextSuggestion} from "./TextSuggestion";
import {DeleteOutlined} from "@ant-design/icons";
import {CarouselSuggestion} from "./CarouselSuggestion";
import {NameSuggestion} from "./NameSuggestion";
import {sendPost} from "../../../http/requester";

type SuggestionsProps = {
    dispatch: React.Dispatch<PlayerAction>;
    state: PlayerState;
};

export const Suggestions = ({
                                dispatch,
                                state
                            }: SuggestionsProps) => {

    const onAdd = (typeToAdd: SuggestionType) => {
        dispatch({type: "add-empty-suggestion", key: '', suggestionType: typeToAdd})
    };

    const onCreate = async () => {
        await sendPost('player', {
            suggestions: JSON.stringify(state.suggestions),
            recordingId: state.data?.id
        })
        dispatch({type: "clear-suggestions"})
    }

    return (
        <>
            {state.suggestions.map((s, i) => {
                let variant = <>Unknown - {s.suggestionType}</>;
                if (s.suggestionType === "text") {
                    variant = <TextSuggestion dispatch={dispatch} suggestion={s} />;
                } else if (s.suggestionType === "carousel") {
                    variant = <CarouselSuggestion suggestion={s} />;
                } else if (s.suggestionType === "name") {
                    variant = <NameSuggestion dispatch={dispatch} suggestion={s} />;
                }

                return (<Row key={`item-${i}`} align={"middle"}>
                    <Col span={1}>
                        <Button type="primary" shape="circle" onClick={() => {
                            dispatch({type: "remove-suggestion", idToDelete: s.id})
                        }} icon={<DeleteOutlined />} />
                    </Col>
                    <Col span={23}>
                        <Row>
                            <Col span={8}>
                                <Typography.Title level={4}>{s.suggestionType} - {s.id}</Typography.Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={18}>
                                {variant}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br />
                            </Col>
                        </Row>
                    </Col>
                </Row>)
            })}
            <Row align={"middle"}>
                <Col>
                    <b>Suggestions:</b>&nbsp;
                </Col>
                <Col>
                    <Button onClick={() => onAdd('text')}>Text</Button>&nbsp;
                    <Button onClick={() => onAdd('name')}>Name</Button>
                    {0 < state.suggestions.length && (<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button type={"primary"} onClick={onCreate}>Create Article</Button></>)}
                </Col>
            </Row>
        </>
    );
}
