import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import {sendPostAuth} from "../../http/requester";

type CreateUserProps = {
    visible: boolean;
    hide: () => void;
};

export const CreateUser = ({
                               visible,
                               hide,
                           }: CreateUserProps): ReactElement => {
    const [error, setError] = useState('');

    const onFinish = async (values: any) => {
        setError('');
        const response = await sendPostAuth('users', {
            email: values.email,
            password: values.password,
        });
        if (response.ok || response.status < 500) {
            const result = await response.json();
            if (result.errorMessage) {
                setError(result.errorMessage);
            } else {
                hide();
            }
        } else {
            setError('Something bad happened. Please contact support.');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    let errorMessage = <></>;
    if (error) {
        errorMessage = (
            <>
                <Row justify={'center'}>
                    <Col>
                        <Alert type={'error'} message={error} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
            </>
        );
    }

    return (
        <Modal
            open={visible}
            centered={true}
            footer={null}
            onCancel={() => hide()}
        >
            {errorMessage}
            <Row justify={'center'}>
                <Col>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Email is required' }]}
                        >
                            <Input autoComplete={'off'} autoCorrect={'off'} />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                { required: true, message: 'Password is required' },
                                { min: 8, message: 'Password must be minimum 8 characters.' },
                                { pattern: /[0-9]/, message: 'Add at least one number' },
                                { pattern: /[A-Z]/, message: 'Add at least one uppercase' },
                                {
                                    pattern: /^[a-zA-Z0-9]/,
                                    message: 'Add at least one non alpanumeric',
                                },
                            ]}
                        >
                            <Input.Password autoComplete={'off'} autoCorrect={'off'} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};
