import * as React from 'react';
import {ReactElement, useEffect} from 'react';

import {updateToken} from '../../http/requester';
import {PageSpin} from "../../components/PageSpin";

export const LoginSuccessOauth = (): ReactElement => {
    useEffect(() => {
        updateToken().then(r => {
            window.location.href = '/';
        });
    }, []);


    return (<>
        <PageSpin/>
    </>);
};
