import React, {useState} from 'react';
import {Button, Col, Row, Select, Tooltip} from 'antd';
import {EditOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {PlayerAction} from "./PlayerAction";
import {PlayerState} from "./PlayerState";
import {DateTime} from "luxon";

type EventViewerProps = {
    dispatch: React.Dispatch<PlayerAction>;
    state: PlayerState;
};

const toVideoTime = (left: any, right: any) => {
    let time;
    if (typeof left === "number") {
        time = DateTime.fromMillis(right).diff(DateTime.fromMillis(left));
    } else {
        time = DateTime.fromMillis(right).diff(DateTime.fromISO(left));
    }
    if (time.isValid) {
        return time.milliseconds / 1000;
    } else {
        return 0;
    }
};

export const EventViewer = ({
                                dispatch,
                                state
                            }: EventViewerProps) => {

    const [include, setInclude] = useState<string[]>(['click', 'mousedown', 'input']);
    const [exclude, setExclude] =
        useState<string[]>(['beforeinput', 'selectionchange']);

    const getDescription = (event: any): string => {
        if (event.type === 'capturing_started') {
            return `title: ${event.title}, url: ${event.url}`
        } else if (event.type === 'click' || event.type === 'submit' || event.type === 'mousedown') {
            return `${event.targetNodeName}, text: ${event.targetText}`
        } else if (event.type === 'input' || event.type === 'keyup') {
            return `label: ${event.targetLabel}`
        } else {
            return '';
        }
    };

    const getEvents = (events: any[] | undefined): any[] => {
        const all = events ?? [];
        if (0 < include.length) {
            return all.filter((e) => 0 <= include.indexOf(e.type) || 0 <= include.indexOf(e.event?.type));
        } else if (0 < exclude.length) {
            return all.filter((e) => -1 === exclude.indexOf(e.type) && -1 === exclude.indexOf(e.event?.type));
        } else {
            return all;
        }
    }

    const onMove = async (e: any) => {
        const videoTime = toVideoTime(state.data?.started, e.created);
        let x = 0;
        let y = 0;
        if(e.event.type === 'click' || e.event.type === 'mousedown') {
            x = e.event.clientX;
            y = e.event.clientY;
        }

        dispatch({type: 'update-video-state', scroll: videoTime, x: x, y: y})
    }

    const getFilters = (events: any[] | undefined): any[] => {
        if (events) {
            const allEventTypes = events.map((e) => e.type);
            const allHtmlEventTypes = events.map((e) => e.event?.type ?? '');
            const types = new Set(allEventTypes.concat(allHtmlEventTypes));
            return Array.from(types).filter((t) => t !== '').map((t) => {
                return {value: t, label: t}
            });
        } else {
            return [];
        }
    }

    return (
        <>
            <Row>
                <Col span={24} title={"Events to include"}>
                    <Select
                        dropdownMatchSelectWidth={true}
                        style={{width: '100%'}}
                        showSearch
                        placeholder="Select an event"
                        optionFilterProp="children"
                        mode={'multiple'}
                        value={include}
                        onChange={(e) => setInclude(e)}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={getFilters(state.data?.events)}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <ul style={{height: '40vh', overflow: 'auto'}}>
                        {getEvents(state.data?.events).map((e, i) => {
                            let text = (<></>);
                            const videoTime = toVideoTime(state.data?.started, e.created);
                            let buttons = (<></>);
                            if (e.type === 'HtmlEvent') {
                                if (0 === e.subEvents.length) {
                                    text = (<>{e.event.type} {getDescription(e.event)}</>);
                                } else {
                                    text = (<>{e.event.type} [{e.subEvents.length}] {getDescription(e.event)}</>);
                                }
                                buttons = (
                                    <>
                                        <Button size={'small'} type={'link'}
                                                onClick={() => setInclude([...include, e.event.type])}
                                                icon={<PlusOutlined/>}/>
                                        <Button size={'small'} type={'link'}
                                                onClick={() => setExclude([...exclude, e.event.type])}
                                                icon={<MinusOutlined/>}/>
                                    </>
                                )
                            } else if (e.type === 'TabComplete') {
                                text = (<>{e.type} - {`title: ${e.title}, url: ${e.url}`}</>);
                            } else if (e.type === 'TabLoading') {
                                text = (<>{e.type} - {e.url}</>);
                            }
                            return (<li key={`${i}-item`}>
                                <Button type={"link"} onClick={() =>
                                    dispatch({type: 'update-video-scroll', scroll: videoTime})}>
                                    {videoTime.toFixed(4)}
                                </Button>
                                <Button type={"link"} icon={<EditOutlined />}
                                        onClick={async () => await onMove(e)}/>
                                {buttons}
                                <Tooltip placement="right" title={JSON.stringify(e)}>
                                    {text}
                                </Tooltip>
                            </li>)
                        })}
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col span={24} title={"Events to exclude"}>
                    <Select
                        dropdownMatchSelectWidth={true}
                        style={{width: '100%'}}
                        showSearch
                        placeholder="Select an event"
                        optionFilterProp="children"
                        mode={'multiple'}
                        value={exclude}
                        onChange={(e) => setExclude(e)}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={getFilters(state.data?.events)}
                    />
                </Col>
            </Row>
        </>);
}
