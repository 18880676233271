import * as React from 'react';
import {ReactElement} from 'react';
import {Modal} from 'antd';

type ConfirmProps = {
    visible: boolean;
};

let promiseResolve: (b:boolean) => void;
let confirm: Promise<boolean> = Promise.resolve(false);

export const showConfirm = (
    setLoading: (v: boolean) => void,
    func: (...args: any[]) => Promise<void>,
    ...args: any[]
): (() => Promise<void>) => {
    return async (...inner: any[]) => {
        confirm = new Promise(function(resolve){
            promiseResolve = resolve;
        });
        setLoading(true);
        const result = await confirm;
        if (result) {
            await func(...args, ...inner);
        }
        setLoading(false);
    };
};

export const Confirm = ({visible}: ConfirmProps): ReactElement => {
    return (
        <Modal
            title="Are you sure?"
            open={visible}
            onOk={() => promiseResolve(true)}
            onCancel={() => promiseResolve(false)}
            okText="Yes"
            cancelText="No"
        >
            &nbsp;
        </Modal>
    )
        ;
};
