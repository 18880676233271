import * as React from 'react';
import { ReactElement, useEffect } from 'react';

import { signOut } from '../../http/requester';
import { Routes } from '../../http/routeBuilder';

export const SignOut = (): ReactElement => {
  useEffect(() => {
    signOut().then(() => {
      window.location.href = Routes.login();
    });
  }, []);

  return <></>;
};
