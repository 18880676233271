import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Layout, Menu} from 'antd';
import {
    TeamOutlined,
    LogoutOutlined,
    CloudOutlined,
    BookOutlined,
    ApiOutlined,
    CameraOutlined,
    FileTextOutlined,
    HomeOutlined,
    AlertOutlined,
} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {Chat} from "../components/Chat";
import {AuthContext, AuthContextState, isSuperAdmin} from "../auth/AuthContext";

const {Content, Footer, Sider} = Layout;

let rawMenuItems = [
    {
        path: '/',
        icon: <HomeOutlined/>,
        title: 'Dashboard',
        id: '',
        order: 0
    },
    {
        path: '/recordings',
        icon: <CameraOutlined/>, //<FundProjectionScreenOutlined />
        title: 'Recordings',
        id: '',
        order: 0
    },
    {
        path: '/articles',
        icon: <FileTextOutlined/>,
        title: 'Articles',
        id: '',
        order: 0
    },
    // {
    //     path: '/knowledge-base',
    //     icon: <BookOutlined/>,
    //     title: 'Knowledge Base',
    //     id: '',
    //     order: 0
    // },
    {
        path: '/portal',
        icon: <CloudOutlined/>,
        title: 'Portal',
        id: '',
        order: 0
    },
    // {
    //     path: '/integrations',
    //     icon: <ApiOutlined/>,
    //     title: 'Integrations',
    //     id: '',
    //     order: 0
    // },
    // {
    //     path: '/analytics',
    //     icon: <LineChartOutlined/>,
    //     title: 'Analytics',
    //     id: '',
    //     order: 0
    // },
    {path: '/team', icon: <TeamOutlined/>, title: 'Team', id: '', order: 0},
    {
        path: '/sign-out',
        icon: <LogoutOutlined/>,
        title: 'Sign Out',
        id: '',
        order: 0
    },
].map((obj, i) => {
    obj.id = (i + 1).toString();
    obj.order = (i + 1);
    return obj;
});

export const MainLayout = (props: any) => {
    const authContext = useContext<AuthContextState>(AuthContext);

    const navigator = useNavigate();
    // eslint-disable-next-line react/prop-types
    const {to, staticContext, children, ...rest} = props;
    const [collapsed, setCollapsed] = useState(false);
    const [menuItems, setMenuItems] = useState(rawMenuItems);
    const [selectedLink, setSelectedLink] = useState('1');
    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    const location = useLocation();
    useEffect(() => {
        const path = location.pathname;
        const item = menuItems.find((i) => i.id !== '1' && path.startsWith(i.path));

        setSelectedLink(item?.id ?? menuItems[0].id);
    }, [location, menuItems]);

    useEffect(() => {
        if (authContext.isAuthorized && isSuperAdmin(authContext.user)) {
            const adminItem =
                menuItems.find((i) => i.path === '/admin');
            if (!adminItem) {
                const id = menuItems.length + 1;
                setMenuItems([...menuItems, {
                    path: '/admin',
                    icon: <AlertOutlined/>,
                    title: 'Admin',
                    id: id.toString(),
                    order: id
                }]);
            }
        }
    }, [authContext.isAuthorized, location]);

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={[selectedLink]}
                    selectedKeys={[selectedLink]}
                    mode="inline"
                    onClick={(e) => {
                        const item = menuItems.at(parseInt(e.key) - 1);
                        if (item) {
                            navigator(item.path);
                        }
                    }}
                    items={menuItems.map((obj, i) => {
                        return {
                            key: obj.id,
                            icon: obj.icon,
                            label: obj.title
                        };
                    })}
                />
            </Sider>
            <Layout className="site-layout" style={{backgroundColor: '#fff'}}>
                <Content>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 360}}
                    >
                        {children}
                    </div>
                </Content>

                <Chat enabled={true} propertyId={"64823f84cc26a871b0217253"} widgetId={"1h2ecg84k"}/>
                <Footer style={{textAlign: 'center'}}>
                    &copy; 2023 |{' '}
                    <a
                        target={'_blank'}
                        href={
                            'https://chrome.google.com/webstore/detail/docudo/iekhakijaglbobdndoinbjgmangkcelj'
                        }
                    >
                        Download Chrome Extension
                    </a>
                    {' | '}
                    <a target={'_blank'} href={'mailto:support@docudo.xyz'} rel="noreferrer">Support</a>
                </Footer>
            </Layout>
        </Layout>
    );
};
