import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'antd';
import {CopyOutlined, UndoOutlined} from "@ant-design/icons";
import { Input } from 'antd';
import {PlayerAction} from "./PlayerAction";
import {PlayerState} from "./PlayerState";
import {sendPost} from "../../http/requester";

const { TextArea } = Input;

type PromptBuilderProps = {
    dispatch: React.Dispatch<PlayerAction>;
    state: PlayerState;
};

export const PromptBuilder = ({
                                  dispatch,
                                  state
                              }: PromptBuilderProps) => {
    const onChatGtp = async () => {
        if (state.data?.prompt) {
            const response = await sendPost(`player/${state.data.id}/prompt`, {
                prompt: state.data?.prompt
            })
            const data = await response.json();
            const answer = JSON.parse(data.raw);
            let content = '';
            for (const c of answer.choices) {
                content += c.message.content + '\r\n';
            }
            dispatch({type: 'set-chat-response', chatResponse: content})
        }
    };

    return (
        <Row>
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <TextArea style={{width: '100%', height: '300px'}}
                                  value={state.data?.prompt}
                                  onChange={(c) => dispatch({type: 'set-prompt', prompt: c.target.value})}
                                  allowClear={true}
                                  autoComplete={'false'}
                                  autoCorrect={'false'}
                                  rows={8} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button shape="circle"
                                onClick={async () => await navigator.clipboard.writeText(state.data?.prompt ?? '')}
                                icon={<CopyOutlined />} />&nbsp;
                        <Button type="primary"
                                onClick={onChatGtp}
                        >Call ChatGtp</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
