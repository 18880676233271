import { Routes } from './routeBuilder';

export default class Auth {
  static STORAGE_KEY = 'token';

  static getToken(): string | null {
    return window.localStorage.getItem(Auth.STORAGE_KEY);
  }

  static setToken(token: string): void {
    window.localStorage.setItem(Auth.STORAGE_KEY, token);
  }

  static removeToken(): void {
    window.localStorage.removeItem(Auth.STORAGE_KEY);
  }
}

declare global {
  const chrome: any;
}

export const sendTokenToChromeExtension = async (token: string) => {
  const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  await chrome?.runtime?.sendMessage(
    extensionId,
    {
      token: token,
    },
    null,
    function (a: any) {
      console.log('sendTokenToChromeExtension');
      console.log(a);
      console.log(chrome?.runtime?.lastError);
    },
  );
};

export const useQueryParams = () => {
  const params = new URLSearchParams(window ? window.location.search : {});

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop as string);
    },
  });
};

const getAuthHeaders = () => {
  return {
    Authorization: `Bearer ${Auth.getToken()}`,
    'Content-Type': 'application/json',
    credentials: 'include',
  };
};

export async function updateToken(): Promise<any> {
  const resp = await fetch(Routes.api.auth.getToken(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (resp.ok) {
    const result = await resp.json();
    const token = result.token;
    Auth.setToken(token);
    await sendTokenToChromeExtension(token);
  }

  return resp.ok;
}

export async function login(obj: any): Promise<any> {
  const resp = await fetch(Routes.api.auth.login(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
    credentials: 'include',
  });

  if (resp.ok) {
    const result = await resp.json();
    const token = result.token;
    Auth.setToken(token);
    await sendTokenToChromeExtension(token);
  }

  return resp.ok;
}

export async function signOut(): Promise<any> {
  const resp = await fetch(Routes.api.auth.signOut(), {
    method: 'POST',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  Auth.removeToken();

  await sendTokenToChromeExtension('');

  return resp.ok;
}

export async function isAuthorized(): Promise<any> {
  const resp = await fetch(Routes.api.auth.me(), {
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  if (resp.ok) {
    await sendTokenToChromeExtension(Auth.getToken() as string);
  }

  return resp.ok;
}

export async function me(): Promise<any> {
  const resp = await fetch(Routes.api.auth.me(), {
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  if (resp.ok) {
    return await resp.json();
  }
  return null;
}

export async function sendPut(url: string, obj: any): Promise<any> {
  const resp = await fetch(Routes.api.url(url), {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(obj),
    credentials: 'include',
  });

  return resp;
}

export async function sendDelete(url: string): Promise<any> {
  const resp = await fetch(Routes.api.url(url), {
    method: 'DELETE',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  return resp;
}

export async function sendGet(url: string): Promise<any> {
  const resp = await fetch(Routes.api.url(url), {
    method: 'GET',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  return resp;
}

export async function sendPostAuth(
    url: string,
    obj: any = null,
): Promise<Response> {
  if (null == obj) {
    return await fetch(Routes.api.urlAuth(url), {
      method: 'POST',
      headers: getAuthHeaders(),
      credentials: 'include',
    });
  } else {
    return await fetch(Routes.api.urlAuth(url), {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(obj),
      credentials: 'include',
    });
  }
}

export async function sendDeleteAuth(url: string): Promise<any> {
  const resp = await fetch(Routes.api.urlAuth(url), {
    method: 'DELETE',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  return resp;
}

export async function sendGetAuth(url: string): Promise<any> {
  const resp = await fetch(Routes.api.urlAuth(url), {
    method: 'GET',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  return resp;
}

export async function sendPost(
    url: string,
    obj: any = null,
): Promise<Response> {
  if (null == obj) {
    return await fetch(Routes.api.url(url), {
      method: 'POST',
      headers: getAuthHeaders(),
      credentials: 'include',
    });
  } else {
    return await fetch(Routes.api.url(url), {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(obj),
      credentials: 'include',
    });
  }
}
