export class AuthApiRouter {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  me(): string {
    return `${this.apiUrl}/auth/me`;
  }

  signOut(): string {
    return `${this.apiUrl}/auth/sign-out`;
  }

  login(): string {
    return `${this.apiUrl}/auth/login`;
  }

  getToken(): string {
    return `${this.apiUrl}/auth/get-token`;
  }

  loginOAuth(): string {
    return `${this.apiUrl}/auth/login-oauth`;
  }
}

export class ApiRouter {
  apiUrl: string;
  authUrl: string;
  auth: AuthApiRouter;

  constructor(apiUrl: string, authUrl: string) {
    this.apiUrl = apiUrl;
    this.authUrl = authUrl;
    this.auth = new AuthApiRouter(authUrl);
  }

  url(url: string): string {
    return `${this.apiUrl}/${url}`;
  }

  urlAuth(url: string): string {
    return `${this.authUrl}/${url}`;
  }

  getVideoUrlFromArticle(id: string): string {
    return `articles/${id}/video`;
  }
}
