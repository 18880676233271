import * as React from 'react';
import { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import {Button, Col, Descriptions, Row, Space, Table, Typography} from 'antd';
import { DateTime } from 'luxon';
import {
    ApplicationUser,
    AuthContext,
    AuthContextState,
} from '../../auth/AuthContext';
import { CreateUser } from './CreateUser';
import { sendDeleteAuth, sendGetAuth } from '../../http/requester';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {Link} from "react-router-dom";
import {DeleteOutlined} from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

export const Team = (): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [spin, setSpin] = useState(false);
    const [createUserModal, setCreateUserModal] = useState(false);
    const [users, setUsers] = useState<ApplicationUser[]>([]);

    const load = () => {
        sendGetAuth(`users`)
            .then((res) => res.json())
            .then((d) => setUsers(d));
    };

    useEffect(() => {
        load();
    }, [createUserModal]);

    const onDelete = async (user: ApplicationUser) => {
        await sendDeleteAuth(`users/${user.id}`);
        load();
    };

    const onCreate = () => {
        setCreateUserModal(true);
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Created',
            key: 'created',
            render: (text: string, row: any) => {
                return (
                    <p key={'created' + row.id}>
                        {DateTime.fromISO(row.created).toFormat('d LLL yyyy, T')}
                    </p>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, row: any) => (
                <Space key={'action' + row.id} size="middle">
                    {authContext.user.id !== row.id ? (
                        <Button
                            type={'link'}
                            onClick={toggleVisibility(setSpin, onDelete, row)}
                            title={"Delete"}>
                            <DeleteOutlined />
                        </Button>
                    ) : (
                        <></>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Fragment>
            <CreateUser
                visible={createUserModal}
                hide={() => {
                    setCreateUserModal(false);
                }}
            />
            <FullPageSpin visible={spin} />
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Team
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={20}>
                    <Paragraph>
                        <blockquote>Add teammates and work together.
                        </blockquote>
                    </Paragraph>
                </Col>
                <Col span={4} style={{textAlign: 'right'}}>
                    <Button type="primary" onClick={onCreate}>+ Add new user</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table rowKey={'id'} dataSource={users} columns={columns} />
                </Col>
            </Row>
        </Fragment>
    );
};
