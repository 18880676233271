import React, {useEffect, useState} from 'react';
import {Typography} from 'antd';
import {Col, Row} from 'antd';
import {sendGet, sendPost} from "../../http/requester";
import {Confirm, showConfirm} from "../../components/Confirm";
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {PageSpin} from "../../components/PageSpin";
import {useParams} from "react-router-dom";
import {Recording} from "../../Domain/Recording";
import {Article} from "../../Domain/Article";
import {ArticleTable} from "../Articles/ArticleTable";

const {Title, Paragraph} = Typography;


export const View = () => {
    const [recording, setRecording] = useState<Recording | null>(null);
    const [articles, setArticles] = useState<Article[]>([]);
    const [loading, setLoading] = useState(true);
    const [spin, setSpin] = useState(false);
    const [video, setVideo] = useState("");
    const [confirm, setConfirm] = useState(false);
    let {id} = useParams();


    const readArticles = async () => {
        const articlesResponse = await sendGet('recordings/' + id + '/articles');
        const articles = await articlesResponse.json();
        setArticles(articles);
    }

    useEffect(() => {
        sendGet('recordings/' + id).then((res) => res.json()).then(async (r) => {
            setRecording(r);

            const response = await sendGet('recordings/' + id + '/video');
            const data = await response.json();
            setVideo(data.video);

            await readArticles();

            setLoading(false);
        })
    }, [id]);

    const setName = async (name: string) => {
        await sendPost('recordings/' + id, {name: name});

        if (recording) {
            setRecording({
                ...recording,
                name
            });
        }
    }

    let body = (<PageSpin/>);
    if (!loading) {
        body = (<>
            <Row>
                <Col span={18}>
                    <Typography.Title ellipsis={true} editable={{onChange: setName}} level={2} style={{margin: 0}}>
                        {recording?.name}
                    </Typography.Title>
                    <Row>
                        <Col>
                            <br/>
                        </Col>
                    </Row>
                    <Row justify={"center"}>
                        <Col>
                            {video !== '' && (<video style={{width: '100%', height: '50vh'}}
                                                     controls={true}>
                                <source src={video}/>
                            </video>)}
                        </Col>
                    </Row>
                    <Row style={{margin: '10px'}} justify={"center"}>
                        <Col span={24}>
                            <ArticleTable onDelete={showConfirm(setConfirm, toggleVisibility(setSpin, async (id) => {
                                await sendPost('articles/' + id + '/delete').then(async (r) => {
                                    await readArticles();
                                })
                            }))} articles={articles}/>
                        </Col>
                    </Row>
                </Col>
                <Col span={6} className={"action-panel"}>
                    <Row><Col><br/></Col></Row>
                </Col>
            </Row>
        </>);
    }

    return (<>
        <Confirm visible={confirm}/>
        <FullPageSpin visible={spin}/>
        {body}
    </>);
}
