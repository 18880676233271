import * as React from 'react';
import {ReactElement, useEffect} from 'react';

import {useNavigate} from 'react-router-dom';
import {Routes} from '../../http/routeBuilder';
import {PageSpin} from "../../components/PageSpin";

export const LoginErrorOauth = (): ReactElement => {
    const navigator = useNavigate();

    useEffect(() => {
        navigator(Routes.home());
    }, []);

    return (<>
        <PageSpin/>
    </>);
};
