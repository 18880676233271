import * as React from 'react';
import {ReactElement, useEffect, useState} from 'react';
import {Form, Input, Button, Row, Col, Alert, Typography} from 'antd';

import {isAuthorized, login} from '../../http/requester';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../../http/routeBuilder';
import {GithubOutlined, GoogleOutlined, WindowsOutlined} from '@ant-design/icons';

export const Login = (): ReactElement => {
    const navigator = useNavigate();
    const [isFailed, setIsFailed] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        isAuthorized().then((isAuthorized) => {
            if (isAuthorized) {
                navigator(Routes.home());
            }
        });
        // start container app
        fetch('https://api.docudo.xyz').then(e => console.log('ok'));
    }, []);

    const onFinish = async (values: any) => {
        setIsFailed(false);
        setLoading(true);
        const ok = await login({
            email: values.username,
            password: values.password,
            remember: true, //values.remember,
        }).then(async (r) => {
            return r;
        }).catch(async () => {
            setLoading(false);
            return false;
        });

        if (ok) {
            window.location.href = '/';
        } else {
            setLoading(false);
            setIsFailed(true);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    let errorMessage = <></>;
    if (isFailed) {
        errorMessage = (
            <Row justify={'center'} style={{marginBottom: '30px'}}>
                <Col>
                    <Alert
                        message="Login failed. Please check your email or password."
                        type="error"
                    />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row style={{marginTop: '10vh'}}>
                <Col>
                    &nbsp;
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col>
                    <Typography.Title>DocuDo</Typography.Title>
                </Col>
            </Row>
            {errorMessage}
            <Row justify={'center'}>
                <Col>
                    <Form
                        name="basic"
                        labelCol={{span: 8}}
                        disabled={isLoading}
                        wrapperCol={{span: 16}}
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="username"
                            rules={[{required: true, message: 'Email is required'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{required: true, message: 'Password is required'}]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        {/*<Form.Item*/}
                        {/*  name="remember"*/}
                        {/*  valuePropName="checked"*/}
                        {/*  wrapperCol={{ offset: 8, span: 16 }}*/}
                        {/*>*/}
                        {/*  <Checkbox>Remember me</Checkbox>*/}
                        {/*</Form.Item>*/}

                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Login
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <a href={Routes.createAccount()}>Create account</a>
                        </Form.Item>
                    </Form>
                    <form
                        autoComplete="off"
                        method={'post'}
                        action={Routes.api.auth.loginOAuth()}
                    >
                        <input type={'hidden'} name={'provider'} value={'github'} />
                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit" icon={<GithubOutlined />}>
                                Login via GitHub
                            </Button>
                        </Form.Item>
                    </form>
                    <form
                        autoComplete="off"
                        method={'post'}
                        action={Routes.api.auth.loginOAuth()}
                    >
                        <input type={'hidden'} name={'provider'} value={'google'} />
                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit" icon={<GoogleOutlined />}>
                                Login via Google
                            </Button>
                        </Form.Item>
                    </form>
                    <form
                        autoComplete="off"
                        method={'post'}
                        action={Routes.api.auth.loginOAuth()}
                    >
                        <input type={'hidden'} name={'provider'} value={'microsoft'} />
                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit" icon={<WindowsOutlined />}>
                                Login via Microsoft
                            </Button>
                        </Form.Item>
                    </form>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 6 }}>
                    &copy; 2023 | <a href={"https://docudo.xyz"}>docudo.xyz</a> | Gen AI for tech writers
                </Col>
            </Row>
        </>
    );
};
