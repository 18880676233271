import React, {useContext, useEffect, useState} from 'react';
import {Button, Space, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {Typography} from 'antd';
import {Col, Row} from 'antd';
import {sendGet, sendPost} from "../../http/requester";
import {Duration} from 'luxon';
import {Confirm, showConfirm} from "../../components/Confirm";
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {AuthContext, AuthContextState, isSuperAdmin} from "../../auth/AuthContext";
import {PageSpin} from "../../components/PageSpin";
import {Link} from "react-router-dom";
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons';


const {Title, Paragraph} = Typography;

interface DataType {
    key: string;
    recording: string;
    thumbnail: string;
    created: string;
    status: string;
    duration: string;
}

export const Listing = () => {
    const [recordings, setRecordings] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [spin, setSpin] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [confirm, setConfirm] = useState(false);
    const authContext = useContext<AuthContextState>(AuthContext);

    const load = () => {
        sendGet('recordings').then((res) => res.json()).then((r) => {
            setRecordings(r.map((a: any) => {
                const seconds = parseInt((a.videoDurationMilliseconds / 1000).toFixed(0));
                const d = Duration.fromObject({seconds: seconds});
                return {
                    key: a.id,
                    recording: a.name,
                    thumbnail: '',
                    created: new Date(a.created).toLocaleString(),
                    duration: d.toHuman({unitDisplay: "short"}),
                    status: a.progressStep,
                }
            }));
            setLoading(false);
        })
    };

    useEffect(() => {
        load();
    }, []);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Recording',
            dataIndex: 'recording',
            key: 'recording',
            render: (text, i) => {
                if (i.status === 'Processed') {
                    return (<Link to={`/recordings/${i.key}`}>{text}</Link>);
                } else if (isSuperAdmin(authContext.user)) {
                    return (<p data-key={i.key}><a href={`/player/${i.key}`}>{text}</a></p>);
                } else {
                    return (<p data-key={i.key}>{text}</p>);
                }
            },
        },
        {
            title: 'Uploaded',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            render: (a, record) => {
                if (record.status === 'Processed') {
                    return (
                        <Space size="middle">

                            <Button type={"link"}
                                    onClick={showConfirm(setConfirm, toggleVisibility(setSpin, async () => {
                                        await sendPost('recordings/' + record.key + '/delete').then(load)
                                    }))} title={"Delete"}><DeleteOutlined /></Button>
                            <Button type={"link"}
                                    onClick={showConfirm(setConfirm, toggleVisibility(setSpin, async () => {
                                        await sendPost('recordings/' + record.key + '/regenerate').then(load)
                                    }))} title={"Update"}><SyncOutlined /></Button>
                        </Space>
                    )
                } else {
                    return (
                        <Space size="middle">
                        </Space>
                    )
                }

            },
            key: 'actions',
        }
    ];

    let body = (<PageSpin/>);
    if (!loading) {
        body = (<Table columns={columns}
                       pagination={{
                           pageSize: pageSize,
                           onShowSizeChange: (c, s) => setPageSize(s)
                       }}
                       dataSource={recordings}/>);
    }

    return (<>
        <Confirm visible={confirm}/>
        <FullPageSpin visible={spin}/>
        <Title>Recordings</Title>
        <Row>
            <Col>
                <Paragraph>
                    <blockquote>
                        Recordings or screencast are used to generate articles for <a href={"/portal"}>the portal</a>.
                        To create a recording download <a target={'_blank'}
                                                          href={"https://chrome.google.com/webstore/detail/docudo/iekhakijaglbobdndoinbjgmangkcelj"}
                                                          rel="noreferrer">chrome
                        extension</a>.
                    </blockquote>
                </Paragraph>
            </Col>
        </Row>
        {body}
    </>);
}
