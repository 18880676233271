import React, {Reducer, useEffect, useReducer, useRef, useState} from "react";
import {Button, Col, Row, Typography} from 'antd';
import {EditState} from "./EditState";
import {EditAction} from "./EditAction";
import {useParams} from "react-router-dom";
import {sendGet, sendPost} from "../../http/requester";
import {ArticleEditor} from "../../Lexical/ArticleEditor";
import './Edit.css';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {Amplitude} from "../../analytics/Amplitude";
import {EditorRetriever} from "../../Lexical/Editor";
import {EditArticleContextWrapper} from "./EditArticleContext";
import {Routes} from "../../http/routeBuilder";

const initialState: EditState = {
    loading: true,
    data: {
        id: -1,
        name: '',
        content: '',
        raw: '',
        status: '',
        created: new Date(),
        suggestions: []
    }
};

const getState = (
    previous: EditState,
    overwrite: any = {},
): EditState => {
    const next = {
        ...previous,
        ...overwrite,
    };

    return next;
};

const reducer: Reducer<EditState, EditAction> = (
    state: EditState,
    action: EditAction,
): EditState => {
    switch (action.type) {
        case "set-data":
            return getState(state, {data: action.data, loading: false})
        case "set-raw":
            const newDataWithRaw = {
                ...state.data,
                raw: action.raw
            };
            return getState(state, {data: newDataWithRaw})
        case "set-html":
            const newDataWithHtml = {
                ...state.data,
                content: action.html
            };
            return getState(state, {data: newDataWithHtml})
        case "set-status":
            const newData = {
                ...state.data,
                status: action.status
            };
            return getState(state, {data: newData})
        case "set-name":
            const dataWithNewName = {
                ...state.data,
                name: action.name
            };
            return getState(state, {data: dataWithNewName})
        default:
            throw new Error();
    }
};

let editorRetrieval: EditorRetriever | null = null;
export const Edit = () => {
    let {id} = useParams();
    const [spin, setSpin] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        sendGet('articles/' + id).then((res) => res.json()).then((r) => {
            if (!r.suggestions || r.suggestions === "") {
                r.suggestions = [];
            } else {
                r.suggestions = JSON.parse(r.suggestions);
            }
            dispatch({type: 'set-data', data: r})
        })
    }, [id]);

    const setName = (name: string) => {
        dispatch({type: 'set-name', name: name})
    }

    const saveArticle = async () => {
        if (editorRetrieval) {
            const [html, raw] = await editorRetrieval.get();
            await sendPost('articles/' + id, {
                name: state.data.name,
                raw: raw,
                content: html,
            })
            dispatch({type: 'set-html', html: html})
            dispatch({type: 'set-raw', raw: raw})
        }
    }

    return (
        <>
            <FullPageSpin visible={spin}/>
            <Row>
                <Col span={18}>
                    {state.data?.status === 'Draft' ? (
                        <Typography.Title editable={{onChange: setName}} level={1} style={{margin: 0}}>
                            {state.data.name}
                        </Typography.Title>) : (<Typography.Title level={1} style={{margin: 0}}>
                        {state.data.name}
                    </Typography.Title>)}
                    <Row>
                        <Col>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        {state.data?.status === 'Draft' && (
                            <Col span={23} style={{height: '450px'}}>
                                <EditArticleContextWrapper articleId={id ?? ""}
                                                           videoUrl={Routes.api.getVideoUrlFromArticle(id ?? "")}>
                                    <ArticleEditor
                                        setEditor={(e) => {
                                            editorRetrieval = e;
                                        }} raw={state.data.raw}/>
                                </EditArticleContextWrapper>
                            </Col>)}

                        {state.data?.status === 'Published' && (
                            <Col span={23} style={{height: '100vh'}}>
                                <iframe frameBorder={0}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        srcDoc={"<!doctype html>\n" +
                                            "<html lang=\"en\">" +
                                            "  <head>" +
                                            "    <meta charset=\"utf-8\">" +
                                            "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">" +
                                            "    <link href=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css\" rel=\"stylesheet\" integrity=\"sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ\" crossorigin=\"anonymous\">" +
                                            "  </head>" +
                                            "  <body contenteditable='false'>" +
                                            state.data?.content +
                                            "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js\" integrity=\"sha384-ENjdO4Dr2bkBIFxQpeoTz1HIcje39Wm4jDKdf19U8gI4ddQ3GYNS7NTKfAdVQSZe\" crossorigin=\"anonymous\"></script>" +
                                            "  </body>" +
                                            "</html>"}
                                />
                            </Col>)}

                    </Row>
                </Col>
                <Col span={6} className={"action-panel"}>
                    <Row><Col><br/></Col></Row>
                    <Row>
                        <Col span={2}>
                            &nbsp;
                        </Col>
                        <Col span={10}>
                            Status: {state.data?.status}
                        </Col>
                    </Row>
                    <Row><Col><br/></Col></Row>
                    <Row align={"middle"}>
                        <Col span={2}>
                            &nbsp;
                        </Col>
                        <Col span={10}>
                            {state.data?.status === 'Draft' ? (
                                    <Button type={"primary"} onClick={toggleVisibility(setSpin, async () => {
                                        await saveArticle();
                                        const a = await Amplitude.getInstance();
                                        await a.track('article_saved', {'articleId': id});
                                    })}>Save</Button>) :
                                (<Button type={"primary"} onClick={toggleVisibility(setSpin, async () => {
                                    await sendPost('articles/' + id + '/draft').then((r) => {
                                        dispatch({type: 'set-status', status: 'Draft'})
                                    })
                                    const a = await Amplitude.getInstance();
                                    await a.track('article_marked_as_draft', {'articleId': id});
                                })}>Mark as draft</Button>)}
                        </Col>
                        <Col span={2}>
                            &nbsp;
                        </Col>
                        <Col span={10}>
                            {state.data?.status === 'Draft' ? (
                                    <Button onClick={toggleVisibility(setSpin, async () => {
                                        await saveArticle()
                                        await sendPost('articles/' + id + '/publish').then((r) => {
                                            dispatch({type: 'set-status', status: 'Published'})
                                        })
                                        const a = await Amplitude.getInstance();
                                        await a.track('article_published', {'articleId': id});
                                    })}>Publish</Button>) :
                                (<></>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>);
}
