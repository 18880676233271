import {ReactElement, ReactNode} from 'react';
import * as React from 'react';
import {sendGet, sendPost} from "../../http/requester";

export type EditArticleContextState = {
    articleId: string;
    videoUrl: string;
    getVideo: () => Promise<string>;
    getScreenshot: (time: number) => Promise<string>;
};

const defaultBusContextState = {
    articleId: "",
    videoUrl: "",
    getVideo: () => Promise.resolve(""),
    getScreenshot: () => Promise.resolve("")
};

export const EditArticleContext = React.createContext<EditArticleContextState>(
    defaultBusContextState,
);

type EditArticleContextWrapperProps = {
    children?: ReactNode | undefined;
    articleId: string;
    videoUrl: string;
};

export const EditArticleContextWrapper = ({
                                              children,
                                              articleId,
                                              videoUrl
                                          }: EditArticleContextWrapperProps): ReactElement => {
    return (
        <EditArticleContext.Provider
            value={{
                articleId: articleId,
                videoUrl: videoUrl,
                getVideo: async () => {
                    const raw = await sendGet(videoUrl);
                    const result = await raw.json()
                    return result.video;
                },
                getScreenshot: async (time: number) => {
                    const captureResponse = await sendPost('articles/' + articleId + '/capture', {
                        timeStamp: time,
                        x: 0,
                        y: 0,
                        text: "0",
                        captureType: "Clean",
                        lupeType: "None",
                    }).then((res) => res.json());
                    return captureResponse.url;
                }
            }}
        >
            {children}
        </EditArticleContext.Provider>
    );
};
